import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CREATE_EMPRESA_INIT,
  LIST_EMPRESA_INIT,
  DELETE_EMPRESA_INIT,
  MODIFY_EMPRESA_INIT
} from '../actionTypes';
import {
  createEmpresaSuccess,
  listEmpresaSuccess,
  deleteEmpresaSuccess,
  modifyEmpresaSuccess,
  empresaErrorMsg
} from './actions';
import { IEmpresa } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { IEmpresaStatusEmpresa } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';

interface FetchListaEmpresasResult {
  isError: boolean;
  empresas: IEmpresa[] | null;
  statusEmpresas: IEmpresaStatusEmpresa[] | null;
  errorMessage: string | null;
}

// -------------------Listar Empresas-----------------------------

const listEmpresaAsync = async ({ mUser }: { mUser: any }) => {
  try {
    const { status, dados, mensagem } = await Empresas.obtemEmpresas(mUser.user);
    if (status) {
      return {
        isError: !status,
        empresas: dados.allEmps,
        statusEmpresas: dados.allStatusEmps,
        errorMessage: null
      };
    }
    return {
      isError: true,
      empresas: null,
      statusEmpresas: null,
      errorMessage: mensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* parseFetchListEmpresas({ payload }: { payload: any, type: any }) {
  const { mUser } = payload;
  try {
    const resp: FetchListaEmpresasResult = yield call(listEmpresaAsync, { mUser });
    if (!resp.isError) {
      yield put(listEmpresaSuccess(resp.empresas, resp.statusEmpresas));
    } else {
      console.error(resp.errorMessage);
      yield put(empresaErrorMsg({ msg: resp.errorMessage }));
    }
  } catch (error: any) {
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchListEmpresa() {
  yield takeEvery(LIST_EMPRESA_INIT, parseFetchListEmpresas);
}

// ---------------------------Criar Empresa---------------------------------------------------------

const createEmpresaAsync = async ({ mUser, empresa }:
  { mUser: any, empresa: IEmpresa }
) => {
  try {
    const { status, dados, mensagem } = await Empresas.cria(mUser.user, empresa);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    console.error(error);
    throw new FarmaError(error);
  }
};

function* createEmpresaMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, empresa } = payload;
  try {
    const { isCreated, msg } = yield call(createEmpresaAsync, {
      mUser,
      empresa: empresa
    });
    if (isCreated) {
      yield put(createEmpresaSuccess({ msg }));
    } else {
      yield put(empresaErrorMsg({ msg }));
    }
  } catch (error: any) {
    console.error(error);
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateEmpresa() {
  yield takeEvery(CREATE_EMPRESA_INIT, createEmpresaMiddle);
}

// ---------------------------atualiza Empresa---------------------------------------------------------

const updateEmpresaAsync = async ({ mUser, empresa, statusEmpresa, setStatus }:
  { mUser: any, empresa: IEmpresa, statusEmpresa: IEmpresaStatusEmpresa, setStatus: boolean }
) => {
  try {
    const { status, dados, mensagem } = await Empresas.atualiza(mUser.user, empresa, statusEmpresa, setStatus);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    console.error(error);
    throw new FarmaError(error);
  }
};

function* updateEmpresaMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, empresa, statusEmpresa, setStatus } = payload;
  try {
    const { isCreated, msg } = yield call(updateEmpresaAsync, {
      mUser,
      empresa: empresa,
      statusEmpresa,
      setStatus,
    });
    if (isCreated) {
      yield put(modifyEmpresaSuccess({ msg }));
    } else {
      yield put(empresaErrorMsg({ msg }));
    }
  } catch (error: any) {
    console.error(error);
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchUpdateEmpresa() {
  yield takeEvery(MODIFY_EMPRESA_INIT, updateEmpresaMiddle);
}

// ---------------------------Deletar Empresa---------------------------------------------------------

const deleteEmpresaAsync = async ({ mUser, empId }:
  { mUser: any, empId: string }
) => {
  try {
    const { status, dados, mensagem } = await Empresas.deleta(mUser.user, empId);
    if (status) {
      return {
        isDeleted: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isDeleted: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    console.error(error);
    throw new FarmaError(error);
  }
};

function* deleteEmpresaMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, empId } = payload;
  try {
    const { isDeleted, msg } = yield call(deleteEmpresaAsync, {
      mUser,
      empId
    });
    if (isDeleted) {
      yield put(deleteEmpresaSuccess({ msg }));
    } else {
      yield put(empresaErrorMsg({ msg }));
    }
  } catch (error: any) {
    console.error(error);
    yield put(empresaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchDeleteEmpresa() {
  yield takeEvery(DELETE_EMPRESA_INIT, deleteEmpresaMiddle);
}

//-----------------------------------------------------------------------
// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchListEmpresa),
    fork(watchCreateEmpresa),
    fork(watchUpdateEmpresa),
    fork(watchDeleteEmpresa)
  ]);
}
