import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { mongoSetDashsToUser } from 'src/api/mongoRealm/functions';
import {
  LIST_TIPOS_DASH_INIT,
  SET_USERS_DASHBOARD_INIT,
  SET_DASHBOARDS_USER_INIT,
  LIST_MY_DASHS_INIT
} from '../actionTypes';
import {
  listTiposDashSuccess,
  setUsersDashSucess,
  setDashsToUserSuccess,
  listMyDashsSuccess
} from './actions';
import { mongoGetMyDashs, mongoSetUserDash } from '../../api/mongoRealm';
import { getEmpresaInit, dashErroMsg } from '../actions';
import initializeMongoCollections from 'src/api/mongoRealm/mongodb';

// ------------------------- Comumn Functions --------------------------------------------------------
const listInfoDashAsync = async ({ collection, option = 'tipos' }) =>
  collection
    .findOne()
    .then((result) => {
      if (result === 'null') {
        return [];
      }
      const { _id } = result;
      const info = result[option];
      const infoDash = {
        id: _id.toString(),
        info,
        isReceived: true
      };
      return infoDash;
    })
    .catch((error) => {
      console.error(error);
      return { id: null, tipos: [], isReceived: false };
    });
// -------------------Listar Tipos da Dash-----------------------------

function* listTiposDashMiddle({ payload }) {
  try {
    const { dashTypes } = yield call(initializeMongoCollections);
    const { id, info, isReceived } = yield call(listInfoDashAsync, {
      collection: dashTypes,
      option: 'tipos'
    });
    if (isReceived) {
      yield put(listTiposDashSuccess({ idTipos: id, tiposDash: info }));
    } else {
      yield put(dashErroMsg({ message: 'Erro ao receber tipos de Daashboards' }));
    }
  } catch (error) {
    console.error(error);
    yield put(dashErroMsg(error));
  }
}

export function* watchListTiposDash() {
  yield takeEvery(LIST_TIPOS_DASH_INIT, listTiposDashMiddle);
}

// ------------------------Atribuir Usuário aos Dashboads -------------------------------

const setUsers = async ({ empresa, userId, dashId, users, mUser }) => {
  const empId = empresa;
  try {
    const isSetUser = await mongoSetUserDash(mUser.user, empId, dashId, users);
    return {
      isError: !isSetUser,
      msg: isSetUser ? 'Usuários atribuidos com Sucesso' : 'Erro ao Atribuir usuários'
    };
  } catch (error) {
    console.error(error);
    return {
      isError: true,
      msg: `Erro ao Atribuir usuários:  ${error}`
    };
  }
};

export function* parseSetUsersDash({ payload }) {
  const { empresa, userId, dashId, users, mUser } = payload;
  // busca por um usuário especificado pelo ID
  const usersData = yield call(setUsers, {
    empresa,
    userId,
    users,
    dashId,
    mUser
  });
  if (!usersData.isError) {
    yield put(setUsersDashSucess(usersData));
    yield put(getEmpresaInit({ mUser, empId: empresa }));
  } else {
    console.error(usersData.msg);
    yield put(dashErroMsg(usersData.msg));
  }
}

export function* watchSetUsersDash() {
  yield takeEvery(SET_USERS_DASHBOARD_INIT, parseSetUsersDash);
}

// ------------------------Atribuir Dashboards ao usuario -------------------------------
// Atualizar dados comunicacao com o banco -------------------------
const setDashsToUserAsync = async ({ mUser, empId, userId, dashboards, before, atual }) => {
  const { isUpdated } = await mongoSetDashsToUser(
    mUser.user,
    empId,
    userId,
    dashboards,
    before,
    atual
  );
  if (isUpdated) {
    return {
      isUpdated: true,
      isError: false,
      msg: 'Dashboards atribuidos com sucesso!'
    };
  } else {
    return {
      isUpdated: false,
      isError: true,
      msg: 'Erro ao atribuir dashboards'
    };
  }
};

// Atualizar dados funcao chamada pelo dispatch ---------------------
function* setDashsToUserMiddle({ payload }) {
  const { mUser, empId, userId, dashboards, before, atual } = payload;
  try {
    const respData = yield call(setDashsToUserAsync, {
      mUser,
      empId,
      userId,
      dashboards,
      before,
      atual
    });
    if (respData.isUpdated) {
      yield put(
        setDashsToUserSuccess({
          isError: respData.isError,
          msg: respData.msg
        })
      );
    } else {
      yield put(dashErroMsg(respData.msg));
    }
  } catch (error) {
    console.error(error);
    yield put(dashErroMsg({ msg: 'Erro ao atualizar dados' }));
  }
}

export function* watchDashsToUser() {
  yield takeEvery(SET_DASHBOARDS_USER_INIT, setDashsToUserMiddle);
}

// -------------------Listar Dashboards-----------------------------

const getMyDashsMongoDB = async ({ mongoUser, empId, userId }) => {
  try {
    const myDashsData = await mongoGetMyDashs(mongoUser.user, empId, userId);
    return { isReceived: true, data: myDashsData };
  } catch (error) {
    console.error(error);
    return {
      isReceived: false,
      data: null
    };
  }
};

function* listDashMiddle({ payload }) {
  const { mUser, empId, userId } = payload;
  try {
    const { data, isReceived } = yield call(getMyDashsMongoDB, {
      mongoUser: mUser,
      empId,
      userId
    });
    if (isReceived) {
      yield put(listMyDashsSuccess({ dashs: data }));
    } else {
      yield put(dashErroMsg({ message: 'Erro ao receber tipos de Daashboards' }));
    }
  } catch (error) {
    console.error(error);
    yield put(dashErroMsg(error));
  }
}

export function* watchListDash() {
  yield takeEvery(LIST_MY_DASHS_INIT, listDashMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchListTiposDash),
    fork(watchSetUsersDash),
    fork(watchDashsToUser),
    fork(watchListDash)
  ]);
}
