import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ObjectID } from 'bson';
import {
  CREATE_TIPOS_DASH_INIT,
  CREATE_DASHBOARD_INIT,
  GET_DASHBOARDS_INIT,
  UPDATE_DASHBOARD_INIT,
  DELETE_DASHBOARD_INIT
} from '../actionTypes';
import {
  createTiposDashSuccess,
  adminErrorMsg,
  createDashboardSuccess,
  getDashboardsSuccess,
  updateDashboardSuccess,
  deleteDashboardSuccess
} from './actions';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import initializeMongoCollections from 'src/api/mongoRealm/mongodb';

// ------------------------- Comumn Functions --------------------------------------------------------
const calcOperation = (operation) => {
  switch (operation) {
    case 'update':
      return {
        isUpdated: true,
        isCreated: false,
        isDeleted: false,
        msg: 'Atualizado'
      };
    case 'delete':
      return {
        isUpdated: false,
        isCreated: false,
        isDeleted: true,
        msg: 'Deletado'
      };
    case 'insert':
      return {
        isUpdated: false,
        isCreated: true,
        isDeleted: false,
        msg: 'Adicionado'
      };
    default:
      return {
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        msg: 'Não Enviado'
      };
  }
};

const createInfoDashAsync = async ({ id, info, operation, option = 'tipos', collection }) => {
  const objectId = new ObjectID(id);
  let filter = { _id: objectId };
  let update = {};
  const name = `${option}.$.name`;
  const idOption = `${option}.id`;
  const icon = `${option}.$.icon`;
  switch (operation) {
    case 'update':
      update = {
        $set: { [name]: info.name, [icon]: info.icon }
      };
      filter = { _id: objectId, [idOption]: info.id };
      break;
    case 'delete':
      update = { $pull: { [option]: { id: info.id } } };
      break;
    case 'insert':
      update = { $addToSet: { [option]: info } };
      break;
    default:
      break;
  }
  const options = { upsert: true };
  return collection
    .updateOne(filter, update, options)
    .then((result) => {
      const { matchedCount, modifiedCount } = result;
      if (matchedCount && modifiedCount) {
        return true;
      } else {
        console.error('Update Emp', result);
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

// ---------------------------Criar Tipos de Dashboards---------------------------------------------------------

function* createTiposDashMiddle({ payload }) {
  const { id, tipo, operation } = payload;
  try {
    const { dashTypes } = yield call(initializeMongoCollections);
    const isCreated = yield call(createInfoDashAsync, {
      id,
      info: tipo,
      operation,
      option: 'tipos',
      collection: dashTypes
    });
    if (isCreated) {
      const optData = calcOperation(operation);
      yield put(createTiposDashSuccess({ ...optData }));
    } else {
      yield put(adminErrorMsg({ message: 'Erro ao deletar arquivo' }));
    }
  } catch (error) {
    console.error(error);
    yield put(adminErrorMsg(error));
  }
}

export function* watchTipoDashEmpresa() {
  yield takeEvery(CREATE_TIPOS_DASH_INIT, createTiposDashMiddle);
}

// ---------------------------Criar Dashboards---------------------------------------------------------

// ----------- CRIAR DASHBOARD -------------//
const createDasboardsAsync = async ({ empId, dash }) => {
  const objectId = new ObjectID(empId);
  const filter = { _id: objectId };
  const update = { $push: { dashboards: dash } };
  const options = { upsert: false };
  const { empresas } = await initializeMongoCollections();
  return empresas
    .updateOne(filter, update, options)
    .then((result) => {
      const { matchedCount, modifiedCount } = result;
      if (matchedCount && modifiedCount) {
        return true;
      } else {
        console.error('Update Emp', result);
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};
function* createDashboardsMiddle({ payload }) {
  const { empId, dash } = payload;
  try {
    const isCreated = yield call(createDasboardsAsync, {
      empId,
      dash
    });
    if (isCreated) {
      yield put(createDashboardSuccess({ empId, dash, msg: 'Dashboard Criado com Sucesso' }));
    } else {
      yield put(adminErrorMsg({ msg: 'Erro ao Criar Dashboard' }));
    }
  } catch (error) {
    console.error(error);
    yield put(adminErrorMsg(error.mensagem));
  }
}

export function* watchCreateDash() {
  yield takeEvery(CREATE_DASHBOARD_INIT, createDashboardsMiddle);
}

// ----------- ATUALIZA DASHBOARD -------------//
const updateDasboardsAsync = async ({ empId, dash }) => {
  const objectId = new ObjectID(empId);
  const filter = { _id: objectId, 'dashboards.id': dash.id };
  const update = { $set: { 'dashboards.$': dash } };
  const options = { upsert: false };
  const { empresas } = await initializeMongoCollections();
  return empresas
    .updateOne(filter, update, options)
    .then((result) => {
      const { matchedCount, modifiedCount } = result;
      if (matchedCount && modifiedCount) {
        return true;
      } else {
        console.error('Update Emp', result);
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};
function* upadateDashboardsMiddle({ payload }) {
  const { empId, dash } = payload;
  try {
    const isCreated = yield call(updateDasboardsAsync, {
      empId,
      dash
    });
    if (isCreated) {
      yield put(updateDashboardSuccess({ empId, dash, msg: 'Dashboard Atualizado com Sucesso' }));
    } else {
      yield put(adminErrorMsg({ msg: 'Erro ao Atualizar Dashboard' }));
    }
  } catch (error) {
    console.error(error);
    yield put(adminErrorMsg(error.mensagem));
  }
}

export function* watchUpdateDash() {
  yield takeEvery(UPDATE_DASHBOARD_INIT, upadateDashboardsMiddle);
}

// ----------- DELETA DASHBOARD -------------//
const deleteDasboardsAsync = async ({ empId, dash }) => {
  const objectId = new ObjectID(empId);
  const filter = { _id: objectId };
  const update = { $pull: { dashboards: {id: dash.id} } };
  const options = { upsert: false };
  const { empresas } = await initializeMongoCollections();
  return empresas
    .updateOne(filter, update, options)
    .then((result) => {
      const { matchedCount, modifiedCount } = result;
      if (matchedCount && modifiedCount) {
        return true;
      } else {
        console.error('Update Emp', result);
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};
function* deleteDashboardsMiddle({ payload }) {
  const { empId, dash } = payload;
  try {
    const isCreated = yield call(deleteDasboardsAsync, {
      empId,
      dash
    });
    if (isCreated) {
      yield put(deleteDashboardSuccess({ empId, dash, msg: 'Dashboard Deletado com Sucesso' }));
    } else {
      yield put(adminErrorMsg({ msg: 'Erro ao Deletar Dashboard' }));
    }
  } catch (error) {
    console.error(error);
    yield put(adminErrorMsg(error.mensagem));
  }
}

export function* watchDeleteDash() {
  yield takeEvery(DELETE_DASHBOARD_INIT, deleteDashboardsMiddle);
}

// ------------- Buscar dados atualizados dashboads ----------------------------------------------------
const getEmpMongoDB = async ({ mUser, empId }) => {
  try {
    const { mensagem, dados, status } = await Empresas.obtem(mUser.user, empId);
    const { id, lojas, dashboards, groupControl, grupos, indelible, config } = dados;
    if(status) {
      const myEmp = {
        id: id,
        name: dados?.name,
        lojas: lojas,
        grupos: grupos,
        groupControl: groupControl,
        dashboards: dashboards,
        indelible: typeof indelible === 'undefined' ? false : indelible,
        config: config
      };
      return { isReceived: status, msg: mensagem, data: myEmp };
    } else {
      return { isReceived: status, msg: mensagem, data: {} };
    }
  } catch (error) {
    console.error('getEmp', error);
    throw new FarmaError(error);
  }
};

export function* parseGetDashboadsData({ payload }) {
  const { mUser, empId } = payload;
  try {
    const userData = yield call(getEmpMongoDB, { mUser, empId });
    if (userData.isReceived) {
      yield put(
        getDashboardsSuccess({
          emp: userData.data
        })
      );
    } else {
      yield put(adminErrorMsg({ msg: userData.msg }));
    }
  } catch (error) {
    yield put(adminErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchListDashboardsInit() {
  yield takeEvery(GET_DASHBOARDS_INIT, parseGetDashboadsData);
}

//-----------------------------------------------------------------------
// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchTipoDashEmpresa),
    fork(watchListDashboardsInit),
    fork(watchCreateDash),
    fork(watchUpdateDash),
    fork(watchDeleteDash)
  ]);
}
