import React, { useState, ReactNode, FC } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { Theme } from '../theme';
import AdminEmpSelection from '../components/AdminEmpSelection';
import DashSelection from '../components/DashSelection';

import NavBar from './NavBar';
import TopBar from './TopBar';
import { Dashboard, Emp } from '../@types/emp';
import type { RootState } from '../redux/types';
import { setEmpresa } from '../redux/actions';

interface DashboardLayoutProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({
  children
}: DashboardLayoutProps) => {
  const { myEmp } = useSelector(
    (state: RootState) => ({
      myEmp: state.account.myEmp
    }),
    shallowEqual
  );
  const classes = useStyles();
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogDash, setDialogDash] = useState<boolean>(false);
  const [empData, setEmp] = useState<Emp>(myEmp);

  const dispatch = useDispatch();
  const history = useHistory();
  const handleEmp = (emp: Emp): boolean => {
    setDialog(false);
    setEmp(emp);
    dispatch(setEmpresa({ empresa: emp }));
    return true;
  };
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleEmpSwitch = (): void => {
    setDialog(!dialog);
  };

  const handleDashSwitch = (): void => {
    setDialogDash(!dialogDash);
  };
  const handleDash = (dash: Dashboard): boolean => {
    setDialogDash(false);
    history.push(`/paineldash/${myEmp.id}/${dash.id}`);
    return true;
  };
  return (
    <div className={classes.root}>
      {/* <AdminEmpSelection
        dialogState={dialog}
        setDialogState={setDialog}
        handleEmp={handleEmp}
      /> */}
      <DashSelection
        dialogState={dialogDash}
        setDialogState={setDialogDash}
        handleDash={handleDash}
      />
      <TopBar
        handleDashSwitch={handleDashSwitch}
        onMobileNavOpen={(): void => setMobileNavOpen(true)}
        empData={empData}
      />
      <NavBar
        onMobileClose={(): void => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
