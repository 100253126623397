import { FarmaError } from 'src/integracoes/modelos/erros';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable
} from 'firebase/functions';
import {
  IUsuarioFirebase,
  IUsuarioFormulario,
  IUsuarioMongoDB
} from 'src/integracoes/modelos/usuarios';
import { app } from './app';
import { montaUsuarioFirebase } from './utils';

const firebaseFunctions = getFunctions(app, 'southamerica-east1');

const criaUsuarioFirebase = async (
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioFirebase | FarmaError>> => {
  const funcao = httpsCallable(firebaseFunctions, 'usuarios-criaUsuario-v1');
  const usuarioFirebase: IUsuarioFirebase = montaUsuarioFirebase(
    usuarioFormulario
  );
  const resposta = funcao(usuarioFirebase)
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        console.log(resp)
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      console.log(erro)
      throw new FarmaError(erro);
    });
  return resposta;
};

const atualizaUsuarioFirebase = async (
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioFirebase | FarmaError>> => {
  const funcao = httpsCallable(
    firebaseFunctions,
    'usuarios-atualizaUsuario-v1'
  );
  const usuarioFirebase: IUsuarioFirebase = montaUsuarioFirebase(
    usuarioFormulario
  );
  const resposta = funcao(usuarioFirebase)
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        console.log(resp)
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      console.log(erro)
      throw new FarmaError(erro);
    });
  return resposta;
};

const removeUsuarioFirebase = async (
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioFirebase | FarmaError>> => {
  const funcao = httpsCallable(firebaseFunctions, 'usuarios-removeUsuario-v1');
  const { uid } = montaUsuarioFirebase(usuarioFormulario);
  const resposta = funcao({ uid })
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        console.log(resp)
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      console.log(erro)
      throw new FarmaError(erro);
    });
  return resposta;
};

const enviaEmailAtivacao = async (
  usuarioFormulario: IUsuarioFormulario
): Promise<IRespostaFuncao<IUsuarioMongoDB | FarmaError>> => {
  const funcao = httpsCallable(
    firebaseFunctions,
    'transacional-enviaEmailAtivacao-v1'
  );
  const { displayName, email } = montaUsuarioFirebase(usuarioFormulario);
  const resposta = funcao({ name: displayName, email })
    .then((result: HttpsCallableResult) => {
      const resp: IRespostaFuncao<any> = result.data as IRespostaFuncao<any>;
      if (!resp.status) {
        console.log(resp)
        throw new FarmaError(resp.dados);
      }
      return resp;
    })
    .catch((erro: any) => {
      console.log(erro)
      throw new FarmaError(erro);
    });
  return resposta;
};

export {
  criaUsuarioFirebase,
  atualizaUsuarioFirebase,
  removeUsuarioFirebase,
  enviaEmailAtivacao
};
