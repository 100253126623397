import * as Realm from 'realm-web';
import app from './app';

interface AuthJwt {
  user: Realm.User | null;
  logged: boolean;
}
export async function loginJwtRealm(jwt: string): Promise<AuthJwt> {
  // Create a Custom JWT credential
  const credentials = Realm.Credentials.jwt(jwt);
  try {
    // Authenticate the user
    const user: Realm.User = await app.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    if (user.id === app.currentUser?.id) return { user, logged: true };
    return { user: null, logged: false };
  } catch (err) {
    console.error('Falha no Login', err);
    return { user: null, logged: false };
  }
}

export function logoutRealm(): unknown {
  return app.currentUser?.logOut();
}

export function hasLoggedInUserRealm(): unknown {
  // Check if there is currently a logged in user
  return app.currentUser?.isLoggedIn;
}

export function getCurrentUserRealm(): unknown {
  // Return the user object of the currently logged in user
  return app.currentUser?.isLoggedIn ? app.currentUser?.profile : null;
}
