/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Collapse
} from '@material-ui/core';
import Logo from '../../components/Logo';
import NavItem from './NavItem';
import { PAPEL1, PAPELDEFAULT } from '../../utils/rbac/papeis';
import HasAccessItemsMenu from '../../utils/rbac/componentsByRole';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { listMenuUrl } from '../../utils/functions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isNil } from 'lodash';
import { sectionsMenu } from 'src/constants';
import AdminEmpSelection from 'src/components/AdminEmpSelection';
import { setEmpresa } from 'src/redux/actions';

function renderNavItems({ items, pathname, depth = 0, myPrivilege }) {
  const filteredItems = HasAccessItemsMenu({ items, myPrivilege });
  return (
    <List disablePadding>
      {filteredItems.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, myPrivilege }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, myPrivilege }) {
  const { title, href, icon, info, items } = item;
  const key = title + depth;
  const open = matchPath(pathname, {
    path: href,
    exact: false
  });

  const navItemProps = {
    depth,
    icon,
    info,
    key,
    open: Boolean(open),
    title
  };

  const renderedNavItem = items
    ? (
      <NavItem {...navItemProps}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items,
          myPrivilege
        })}
      </NavItem>
    )
    : (
      <NavItem
        depth={depth}
        href={href}
        icon={item.icon}
        info={info}
        key={key}
        title={title}
      />
    );

  acc.push(renderedNavItem);
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  iconOpenClose: {
    marginTop: 12,
    float: 'right',
    color: theme.palette.text.secondary
  },
  button: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    letterSpacing: 0,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%'
  },
  rodape: {
    paddingTop: theme.spacing(2)
  },
  subHeader: {
    float: 'left'
  },
  subHeaderFont: {
    color: theme.palette.text.secondary
  },
  active: {
    color: `${theme.palette.primary.main}!important`
  },
  containerNav: {
    display: 'flex',
    flexDirection: 'column'
  },
  topoNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 10px'
  },
  footerNav: {
    flex: 'auto',
    flexDirection: 'column-reverse'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const isMountedRef = useIsMountedRef();

  const {
    myPrivilege,
    empId,
    myEmp,
    myDashs,
    tiposDash,
    categoriasDash
  } = useSelector(
    (state) => ({
      myEmp: state.account.myEmp,
      myPrivilege: String(state.account.user?.privilegeHash || PAPELDEFAULT),
      empId: state.account.myEmp.id,
      empName: state.account.myEmp.name,
      myDashs: state.dashs.dashs,
      tiposDash: state.dashs.tiposDash,
      categoriasDash: state.categorias.categorias,
    }),
    shallowEqual
  );

  const userPermission = myPrivilege; // Defina as permissões do usuário
  const [sections, setSection] = useState([]);
  const [open, setOpen] = useState(true);
  const [categoriaPath, setCategoriaPath] = useState('');

  const dispatch = useDispatch();
  const handleEmp = (emp) => {
    dispatch(setEmpresa({ empresa: emp }));
    return true;
  };

  const hasSectionPermission = (sectionPermissions, userPermissions) => {
    if (!sectionPermissions) {
      // Se a seção não especifica permissões, ela é acessível para todos
      return true;
    }
    // Verifique se o usuário possui pelo menos uma das permissões da seção
    return sectionPermissions.some((permission) => userPermissions.includes(permission));
  };

  const filteredSections = sectionsMenu.filter((section) => {
    return hasSectionPermission(section.allowedRoles, userPermission);
  });

  // preenche a lista de menus "sections", com a lista de dashboards que esta atribuida ao usuario logado
  // utilizado pelo usuario cliente (PAPEL1)
  useEffect(() => {
    const sectionsloader = () => {
      if (isMountedRef.current && !isNil(categoriasDash) && !isNil(tiposDash) && !isNil(myDashs)) {
        const fullDash = categoriasDash
          .map((categ) => {
            const cateDash = myDashs
              .map((dash) => {
                if (categ.id === dash.categoriaId) {
                  const myType = tiposDash.filter(
                    (tipo) => tipo.id === dash.tipoId
                  )[0];
                  return {
                    id: dash.id,
                    name: dash.name,
                    icon: typeof myType?.icon !== 'undefined' && myType.icon,
                    url: dash.url
                  };
                }
                return null;
              })
              .filter((dash) => dash !== null);
            if (cateDash.length > 0) {
              return {
                categoria: categ.name,
                categoriaId: categ.id,
                dashs: cateDash
              };
            }
            return null;
          })
          .filter((dash) => dash !== null);
        const lista = listMenuUrl({
          baseUrl: 'dashboard',
          itens: fullDash,
          empId,
          subHeaderName: 'categoria',
          subHeaderIdName: 'categoriaId',
          itemName: 'dashs',
          subItemName: 'id'
        });
        setSection(lista);
      }
    };
    sectionsloader();
  }, [categoriasDash, empId, isMountedRef, myDashs, tiposDash]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  // funcao utilizada no menu do cliente (PAPEL1) 
  const handleOpen = (section) => {
    if (section.href === categoriaPath) {
      setCategoriaPath('/');
    } else {
      setCategoriaPath(section.href);
    }
    setOpen(!open);
  };

  // funcao utilizada no menu do cliente (PAPEL1) 
  const matchOpen = (href) => {
    return matchPath(categoriaPath, {
      path: href,
      exact: false
    });
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar className={classes.containerNav} options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <AdminEmpSelection handleEmp={handleEmp} />
        <Divider />
        <Box p={2}>

          {filteredSections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                myPrivilege
              })}
            </List>
          ))
          }
          {
            myPrivilege === PAPEL1 &&
            sections.length > 0 &&
            sections.map((section) => (
              <List
                key={section.subheader}
                subheader={
                  <>
                    <Button
                      className={classes.button}
                      fullWidth
                      size="small"
                      onClick={() => handleOpen(section)}
                    >
                      <ListSubheader
                        disableGutters
                        disableSticky
                        className={classes.subHeader}
                      >
                        <Typography
                          align="left"
                          className={classes.subHeaderFont}
                          display="inline"
                          variant="overline"
                        >
                          {section.subheader}
                        </Typography>
                        {'     '}
                      </ListSubheader>
                      {matchOpen(section.href) ? (
                        <ExpandLessIcon className={classes.iconOpenClose} />
                      ) : (
                        <ExpandMoreIcon className={classes.iconOpenClose} />
                      )}
                    </Button>
                  </>
                }
              >
                <Collapse in={matchOpen(section.href)}>
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                    myPrivilege
                  })}
                </Collapse>
              </List>
            ))}
        </Box>
        <Divider />
        <Box className={classes.footerNav} display="flex">
          <Typography
            align="center"
            display="inline"
            variant="body2"
            color="textSecondary"
          >
            Versão do software: 1.0.0
          </Typography>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;