import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CREATE_FATOR_INIT, UPDATE_FATOR_INIT, FATOR_LIST_FATORES_EMPRESA_INIT, FATOR_OBTEM_FATOR_INIT } from '../actionTypes';
import {
  createFatorSucess,
  updateFatorSuccess,
  fatorErrorMsg,
  obtemListaFatoresEmpresaSuccess,
  obtemFatorSuccess
} from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { IFator } from 'src/integracoes/servicos/mongo-atlas/modelos/IFator';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { Fatores } from 'src/integracoes/servicos/mongo-atlas/funcoes/fatores';
// Commmon functions ------------------------------------------------------------------------

interface FetchListaFatoresResult {
  isError: boolean;
  fator: IFator[] | null;
  errorMessage: string | null;
}

interface FetchFatorResult {
  isError: boolean;
  fator: IFator | null;
  errorMessage: string | null;
}

// ---------------------------Criar Fator---------------------------------------------------------

const createFatorAsync = async ({ mUser, fator }:
  { mUser: any, fator: IFator }
) => {
  try {
    const { status, dados, mensagem } = await Fatores.cria(mUser.user, fator);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    console.error(error);
    throw new FarmaError(error);
  }
};

function* createFatorMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, fatorData } = payload;
  try {
    const { isCreated, msg, data } = yield call(createFatorAsync, {
      mUser,
      fator: {
        empId: fatorData.empId,
        ano: parseInt(fatorData.ano, 10),
        mes: parseInt(fatorData.mes, 10),
        loja: fatorData.loja,
      }
    });
    if (isCreated) {
      yield put(createFatorSucess({ current: data, msg }));
    } else {
      yield put(fatorErrorMsg({ msg }));
    }
  } catch (error: any) {
    console.error(error);
    yield put(fatorErrorMsg({ msg: error.msg }));
  }
}

export function* watchCreateFator() {
  yield takeEvery(CREATE_FATOR_INIT, createFatorMiddle);
}

// ------------------------------ Atualizar Fator-------------------------------------------
const updateFatorAsync = async ({ mUser, fator }:
  { mUser: any, fator: IFator }) => {
  try {
    const resposta = await Fatores.atualiza(
      mUser.user,
      fator
    );
    if (resposta.status) {
      return { isUpdated: true, msg: 'Fator Atualizado com Sucesso' };
    } else {
      return {
        isUpdated: false,
        msg: 'Erro ao Atualizar Fator'
      };
    }
  }
  catch (error: any) {
    throw new FarmaError(error);
  }
};
function* updateFatorMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, fator } = payload;
  try {
    const { isUpdated, msg } = yield call(updateFatorAsync, {
      mUser,
      fator
    });
    if (isUpdated) {
      yield put(updateFatorSuccess({ msg }));
    } else {
      yield put(fatorErrorMsg({ msg }));
    }
  } catch (error: any) {
    console.error(error);
    yield put(fatorErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchUpdateFator() {
  yield takeEvery(UPDATE_FATOR_INIT, updateFatorMiddle);
}


// -------------------- LISTAR FATORES EMPRESA----------------------------------------- //

const listarFatoresEmpresa = async ({ mUser, empId }: { mUser: any, empId: string }): Promise<FetchListaFatoresResult> => {
  try {
    const { status, dados } = await Empresas.obtemFatores(mUser.user, empId);
    if (Array.isArray(dados)) {
      return {
        isError: !status,
        fator: dados,
        errorMessage: null
      };
    }
    return {
      isError: true,
      fator: null,
      errorMessage: 'Falha ao obter dados'
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchFatorEmpresa({ payload }: { payload: any, type: any }) {
  const { mUser, empId } = payload;
  try {
    const fatorData: FetchListaFatoresResult = yield call(listarFatoresEmpresa, { mUser, empId });
    if (!fatorData.isError) {
      yield put(obtemListaFatoresEmpresaSuccess(fatorData));
    } else {
      console.error(fatorData.errorMessage);
      yield put(fatorErrorMsg(fatorData.errorMessage));
    }
  } catch (error: any) {
    yield put(fatorErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchFatorEmpresa() {
  yield takeEvery(FATOR_LIST_FATORES_EMPRESA_INIT, parseFetchFatorEmpresa);
}

// -------------------- OBTER FATOR ----------------------------------------- //

const obterFator = async ({ mUser, fator}: { mUser: any, fator: IFator }): Promise<FetchFatorResult> => {
  try {
    const { status, dados } = await Fatores.obtem(mUser.user, fator);
    if (status) {
      return {
        isError: !status,
        fator: dados as IFator,
        errorMessage: null
      };
    }
    return {
      isError: !status,
      fator: null,
      errorMessage: 'Falha ao carregar dados'
    };

  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchFator({ payload }: { payload: any, type: any }) {
  const { mUser, fator } = payload;
  try {
    const fatorData: FetchFatorResult = yield call(obterFator, { mUser, fator });
    if (!fatorData.isError) {
      yield put(obtemFatorSuccess(fatorData));
    } else {
      console.error(fatorData.errorMessage);
      yield put(fatorErrorMsg({ msg: fatorData.errorMessage }));
    }
  } catch (error: any) {
    yield put(fatorErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchFator() {
  yield takeEvery(FATOR_OBTEM_FATOR_INIT, parseFetchFator);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchCreateFator),
    fork(watchUpdateFator),
    fork(watchFetchFatorEmpresa),
    fork(watchFetchFator)
  ]);
}
