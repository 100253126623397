import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector, shallowEqual } from 'react-redux';
import { PAPEL1 } from 'src/utils/rbac/papeis';
import { READ, EMPRESA, DASHBOARD } from 'src/utils/rbac/tablePermissionsNames';
import ComponentsByRequiredPrivilegesJSX from 'src/utils/rbac/ComponentsByRequiredPrivilegesJSX';
import useComponentsByRequiredPrivileges from 'src/utils/rbac/useComponentsByRequiredPrivileges';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Button,
  Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { RootState } from '../../redux/types';
import Logo from '../../components/Logo';
import { Theme } from '../../theme';
import Account from './Account';
import { Emp } from '../../@types/emp';

interface TopBarProps {
  onMobileNavOpen: (data: any) => any;
  handleDashSwitch: () => void;
  empData: Emp;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    minHeight: 64
  },
  empName: {
    paddingLeft: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({
  onMobileNavOpen,
  handleDashSwitch,
  empData
}: TopBarProps) => {
  const classes = useStyles();

  const { myPrivilege } = useSelector(
    (state: RootState) => ({
      myPrivilege: String(state.account.user.privilegeHash)
    }),
    shallowEqual
  );

  // permissoes
  const permissionDASHBOARDREAD = useComponentsByRequiredPrivileges({
    module: DASHBOARD,
    privileges: [READ]
  });

  return (
    <AppBar className={clsx(classes.root)}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={1} mr={1}>
          {permissionDASHBOARDREAD && myPrivilege !== PAPEL1 && (
            <Button variant="outlined" size='small' onClick={handleDashSwitch} color="primary">
              Selecionar Dashboard
            </Button>
          )}
        </Box>
        <Account />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
